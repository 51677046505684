<template>
  <span>
    <Registration></Registration>
  </span>

</template>
<script>
import Registration from "@/components/Registration";
export default {
  name: 'RegistrationPage',
  components: {Registration}
};
</script>
