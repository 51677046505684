<template>
  <v-container>
  <v-card>
    <v-card-title style="word-break: normal">
        <p class="text-h4">
          Der 14. Lauf gegen Rechts findet am  25. Mai 2025 statt.
          Die Anmeldung öffnet im Februar 2025
        </p>
    </v-card-title>
   </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RaceStatus",
  data: () => ({
    raceInfo: {}
  }),
  mounted: function () {
    this.getRace()
  },
  methods: {
    getRace() {
      const url = `${this.$base_url}/race/`

      axios.get(url)
          .then((response) => {
            this.raceInfo = response.data
            this.$store.commit('setRaceStatus', response.data.isOpen)
            this.$store.commit('setOnSite', response.data.onSiteEnabled)
            this.$store.commit('setShirtsEnabled', response.data.shirtsEnabled)
          })
    }
  }
}

</script>

<style scoped>

</style>
